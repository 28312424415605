// Part of the SPARKL educational activity system, Copyright 2021 by Pepper Williams
import { mapState, mapGetters } from 'vuex'

export default {
	data() { return {
		comment_update_timeout: null,
		comments_table_starting_comment_id: -1,
	}},
	computed: {
		...mapState(['comment_groups']),
		...mapGetters([]),
	},
	watch: {
	},
	methods: {
		get_comments_for_framework() {
			// can't get comments if not signed in
			if (!this.signed_in) return

			// don't get comments if this framework isn't showing
			if (vapp.framework_list_component.case_tree_lsdoc_identifier != this.lsdoc_identifier) {
				// console.log('framework not showing, so not retrieving comments')
				return
			}

			// retrieve from the DB comments and comment_groups for a framework viewable to the current user
			let payload = {framework_identifier: this.lsdoc_identifier}

			// if we haven't already done the initial comment load, do so
			if (!this.$store.state.comments_previous_update_timestamp[this.lsdoc_identifier]) {
				this.$store.dispatch('get_comments_for_framework', payload).then(()=>{
					// schedule to update the comments soon
					this.schedule_comment_update()

					// no need to do anything else when this returns; components will take care of showing the right comments

				}).catch((e)=>{
					console.log(e)
					// U.loading_stop()
					this.$alert('An error occurred when loading comments.')
				})

			} else {
				// else if this user is in any groups for this framework, get updates
				// (if the user isn't in any groups, definitionally the user wouldn't have any updates to get.
				// The user could in theory be *added* to a group while they're logged in, but we'll live with that edge case; in that case, the user just has to refresh the browser)
				if (this.comment_groups.findIndex(x=>x.framework_identifier == this.lsdoc_identifier) == -1) {
					// if the public_review option is on and the user is a framework editor, have to check for public_review comments even if there aren't any comment groups
					if (!(this.public_review_on && this.user_can_edit)) {
						return
					}
				}

				// also don't get updates if the user turned comments off
				if (vapp.case_tree_component && !vapp.case_tree_component.show_comments) {
					return
				}

				this.$store.dispatch('get_comment_updates_for_framework', payload).then((result)=>{
					let msg = ''
					if (result.archive_count > 0) {
						msg += sr(' $1 $2 been archived.', result.archive_count, U.ps('comment has', result.archive_count, 'comments have'))
					}

					if (result.new_count > 0) {
						msg += sr(' $1 $2 been added.', result.new_count, U.ps('comment has', result.new_count, 'comments have'))
					}

					if (result.update_count > 0) {
						msg += sr(' $1 $2 been updated.', result.update_count, U.ps('comment has', result.update_count, 'comments have'))
					}

					if (msg) {
						this.$inform(msg)
					}

					// schedule to update the comments again soon
					this.schedule_comment_update()

				}).catch((e)=>{
					console.log(e)
					// U.loading_stop()
					this.$alert('An error occurred when loading comment updates.')
				})
			}
		},

		schedule_comment_update(time = this.$store.state.comments_update_interval) {
			// clear previous timeout if it was somehow set
			clearTimeout(this.comment_update_timeout)
			this.comment_update_timeout = setTimeout(x=>this.get_comments_for_framework(), time)
		},
	}
}
