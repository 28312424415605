<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
<v-dialog v-model="dialog_open" max-width="1000" persistent scrollable>
	<v-card>
		<v-card-title style="border-bottom:1px solid #999">
			<b>Manage Comment Groups</b>
			<v-spacer/>
			<v-icon color="light-blue" class="mr-2" large @click="U.show_help('comment_groups')">fas fa-info-circle</v-icon>
			<v-btn small text color="primary" @click="new_group_clicked" class="mr-2"><v-icon small class="mr-2">fas fa-plus</v-icon>Add New Group</v-btn>
			<v-btn small text color="primary" @click="onJoinGroupClicked" class="mr-2">
				<v-icon small class="mr-2">
					fas fa-plus
				</v-icon>
				Join Group With Code
			</v-btn>
			<v-btn small color="secondary" @click="$emit('dialog_cancel')" class="ml-4 mr-1"><v-icon small class="mr-2">fas fa-times</v-icon>Done</v-btn>
		</v-card-title>
		<v-card-text class="mt-3" style="font-size:16px; color:#000;">
			<div v-if="group_rows" style="clear:both">
				<v-data-table light dense
					:headers="headers"
					:items="group_rows"
					hide-default-footer
					:items-per-page="-1"
					class="k-admin-users-table"
					no-data-text="Click the button above to add a comment group."
				>
					<template v-slot:item="{ item }"><tr>
						<td><v-btn icon x-small class="mr-2" @click="edit_group(item)"><v-icon>fas fa-edit</v-icon></v-btn>{{item.name}}</td>
						<td class="text-left">{{item.admin_user_names}}</td>
						<td class="text-left">{{item.user_names}}</td>
						<td class="text-center">{{item.comment_count}}</td>
						<td>
							<div class="group-code">
								{{ item.group_code }}
								<CopyBtn size="small" :val="item.group_code" color="primary"/>
							</div>
						</td>
					</tr></template>
				</v-data-table>
			</div>
			<CommentGroupEdit v-if="group_being_edited" :group_being_edited="group_being_edited" @editor_cancel="group_being_edited=null" @group_edited="group_edited"></CommentGroupEdit>
		</v-card-text>
	</v-card>
</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CopyBtn from '../utilities/CopyBtn'
import CommentGroupEdit from './CommentGroupEdit'

export default {
	components: { CommentGroupEdit, CopyBtn },
	props: {
		framework_record: { type: Object, required: true },
	},
	data() { return {
		dialog_open: true,
		headers: [
			{ text: 'Group Name', align: 'left', sortable: false, value:'name' },
			{ text: 'Admin Users', align: 'left', sortable: false, value:'admin_user_names' },
			{ text: 'All Users', align: 'left', sortable: false, value:'user_names' },
			{ text: 'Comment Count', align: 'center', sortable: false, value:'comment_count' },
			{ text: 'Group Code', align: 'center', sortable: false, value:'group_code' },
		],
		group_being_edited: null,
		show_info_dialog:false
	}},
	computed: {
		...mapState(['user_info', 'comment_groups']),
		...mapGetters(['comments_hash']),
		framework_identifier() { return this.framework_record.lsdoc_identifier },
		group_rows() {
			let arr = []

			for (let cg of this.comment_groups) {
				if (cg.framework_identifier == this.framework_identifier) {

					let o = {
						comment_group: cg,
						name: cg.name,
						user_names: cg.user_names.join(', '),
						admin_user_names: '',
						comment_count: 0,
						group_code: cg.group_code
					}

					// get admin user names
					for (let auid of cg.admin_user_ids) {
						let i = cg.user_ids.findIndex(x=>x==auid)
						if (i > -1) {
							if (o.admin_user_names) o.admin_user_names += ', '
							o.admin_user_names += cg.user_names[i]
						}
					}

					// get count of comments
					if (this.comments_hash[this.framework_identifier]) {
						for (let item_identifier in this.comments_hash[this.framework_identifier]) {
							for (let c of this.comments_hash[this.framework_identifier][item_identifier]) {
								if (c.comment_group_id == cg.comment_group_id) {
									++o.comment_count
								}
							}
						}
					}

					arr.push(o)
				}
			}
			// We check each group and if it does not already have a group code,
			// we generate one.
			arr.forEach(item => {
				if (!item.group_code) {
					this.generate_group_code(item)
				}
			})
			return arr
		}
	},
	created() {
	},
	mounted() {
	},
	methods: {
		/**
		 * Checks the validity of a given group code.
		 * @param {string} code
		 * @returns {boolean} returns true if the code is a valid group code, false otherwise..
		 */
		verifyGroupCode(code) {
			return /^[ABCEFHJKLMNPRSTWXYZ]{5}$/.test(code);
		},
		new_group_clicked() {
			this.group_being_edited = new Comment_Group({
				framework_identifier: this.framework_identifier,
				creator_user_id: this.user_info.user_id,
				admin_user_ids: [this.user_info.user_id],
				user_ids: [this.user_info.user_id],
				user_names: [this.user_info.first_name + ' ' + this.user_info.last_name],
			})
		},

		edit_group(group_row) {
			this.group_being_edited = group_row.comment_group
		},
		/**
		 * Event handler for when the 'Join group by code' button is clicked
		 * @returns {void}
		 */
		onJoinGroupClicked() {
			// Prompts the user to enter the group code
			this.$prompt({
				title: 'Join Group with Code',
				text: 'Type or paste in the code for the group you would like to join',
				acceptText: 'Join Group',
			}).then(code => {
				// Checks if the code was given
				if (!code) {
					this.$alert('No group code entered')
					vapp.ping()
					return
				}
				// Checks if the code is valid
				if (!this.verifyGroupCode(code)) {
					this.$alert('Invalid group code')
					vapp.ping()
					return
				}
				// Creates the payload for the ajax request
				const payload = {
					group_code: code,
					user_id: this.user_info.user_id,
					user_name: this.user_info.first_name + ' ' + this.user_info.last_name,
					framework_identifier: this.framework_identifier,
				}
				// Sends ajax post to PHP service to join the group
				U.loading_start()
				U.ajax('comment_group_join_with_group_code', payload, result => {
					U.loading_stop()
					// Handles error when the group code given was for a framework beside the current one
					if (result.status == 'other_framework') {
						const otherFramework = this.$store.getters.filtered_framework_records.find(record => {
							return record.lsdoc_identifier === result["other_framework_id"]
						})
						this.$alert(result.error + otherFramework.json.CFDocument.title)
						vapp.ping()
						return
					}
					// Handles other generic errors
					if (result.status != 'ok') {
						this.$alert('Error joining group with group code: ' + result.error)
						vapp.ping()
						return
					}
					// Group was joined successfully so we update the local state to reflect that
					const newGroup = new Comment_Group(result.group)
					this.$store.commit('set', [this.comment_groups, 'PUSH', newGroup])
					this.$alert('Successfully joined the group')
					vapp.case_tree_component.schedule_comment_update(0)
				})
			})
		},
		/**
		 * This method generates a new group code and is used when a group is created, or a
		 * pre-existing group does not have a code already.
		 * @param {{
		 *   comment_group: {
		 *     commend_group_id: string
		 *   }
		 * }} groupRow 
		 */
		generate_group_code(groupRow) {
			// Create payload with the group ID and user ID
			const payload = {
				comment_group_id: groupRow.comment_group.comment_group_id,
				user_id: this.user_info.user_id
			}
			// Sends request to php service
			U.loading_start()
			U.ajax('comment_group_generate_group_code', payload, result => {
				U.loading_stop()
				//handles generic erros
				if (result.status != 'ok') {
					this.$alert('Error in generating group code: ' + result.status)
					vapp.ping()
					return
				}
				// Update the group code in the store
				this.$store.commit('set', [groupRow.comment_group, 'group_code', result.group_code])
				// Call schedule_comment_update to ensure we're getting latest data
				vapp.case_tree_component.schedule_comment_update()
			})
		},
		group_edited(payload) {
			let new_group = (this.group_being_edited.comment_group_id == 0)

			// transfer values set in the editor to group_being_edited, which will be in the store if it's an existing group
			for (let key in payload) {
				if (new_group) {
					this.group_being_edited[key] = payload[key]
				} else {
					this.$store.commit('set', [this.group_being_edited, key, payload[key]])
				}
			}

			// now create payload to send to the server: all values for the group, plus user_info.user_id
			payload = Object.assign({}, this.group_being_edited)
			payload.user_id = this.user_info.user_id

			U.loading_start()
			U.ajax('save_comment_group', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					this.$alert('Error in admin ajax call: ' + result.status); vapp.ping(); return;
				}

				let msg
				if (new_group) {
					this.$alert('New comment group created.')
					// set the comment_group_id and add the new group to the store
					this.group_being_edited.comment_group_id = result.comment_group_id
					this.group_being_edited.group_code = result.group_code
					this.$store.commit('set', [this.comment_groups, 'PUSH', this.group_being_edited])
				}

				// close group editor
				this.group_being_edited = null

				// call schedule_comment_update, to make sure we start retrieving updates periodically
				vapp.case_tree_component.schedule_comment_update()
			});
		},
	}
}
</script>

<style lang="scss">
.group-code {
	display: flex;
	gap: 4px;
	align-items: center;
	justify-content: center;
}
</style>
